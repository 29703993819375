/**
 * Created by vladislav on 31/10/2022
 */

const Customers = function () {
    cleverapps.EventEmitter.call(this);

    this.customers = [];

    this.stock = {};
};

Customers.prototype = Object.create(cleverapps.EventEmitter.prototype);
Customers.prototype.constructor = Customers;

Customers.prototype.addCustomer = function (customer) {
    this.customers.push(customer);
};

Customers.prototype.removeCustomer = function (customer) {
    const index = this.customers.indexOf(customer);
    if (index !== -1) {
        this.customers.splice(index, 1);
    }
};

Customers.prototype.listActive = function () {
    return this.customers.filter((customer) => customer.getCurrentRecipe());
};

Customers.prototype.onUnitAvailable = function (unit) {
    if (Customers.IGNORES_STOCK.indexOf(unit.code) !== -1 || !Buildable.IsBuilt(unit) || !Creatable.IsCreated(unit) || unit.isGrounded()) {
        return;
    }

    if (unit.stocked > 0) {
        cleverapps.throwAsync(`Unit added second time ${Unit.GetKey(unit)}`);
        return;
    }

    const key = Unit.GetKey(unit);

    const mapUnit = Map2d.currentMap.getUnit(unit.x, unit.y);
    if (!mapUnit || Unit.GetKey(mapUnit) !== key) {
        return;
    }

    this.stock[key] = (this.stock[key] || 0) + 1;

    unit.stocked = 1;

    this.updateMarks();
    this.onRecipesUpdated();
};

Customers.prototype.onUnitRemoved = function (unit) {
    if (unit.stocked < 1) {
        cleverapps.throwAsync(`Unit removed second time ${Unit.GetKey(unit)}`);
        return;
    }

    if (!unit.stocked) {
        return;
    }

    const key = Unit.GetKey(unit);
    this.stock[key] = (this.stock[key] || 0) - 1;

    unit.stocked -= 1;

    this.updateMarks();
    this.onRecipesUpdated();
};

Customers.prototype.onRecipesUpdated = function () {
    this.trigger("onRecipesUpdated");
};

Customers.prototype.updateMarks = function () {
    this.needUpdateMarks = true;

    Map2d.currentMap.counter.trigger();
};

Customers.prototype.clearWantsWindow = function () {
    this.customers.forEach((customer) => {
        customer.wantsWindow = undefined;
    });
};

Customers.prototype.process = function () {
    if (cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    let wantingWindowCustomer;
    const requiredUnits = {};
    
    this.customers.forEach((customer) => {
        if (customer.getState() === Customer.STATE_EMPTY && !customer.getCooldownTimeLeft() && customer.hasSomeRecipes()) {
            customer.order();
        }

        customer.replaceOrderItemsWithCoins();

        const recipe = customer.getCurrentRecipe();
        if (recipe) {
            const wasReadyForCooking = recipe.wasReadyForCooking;
            recipe.wasReadyForCooking = recipe.isEnoughIngredients();

            if (wasReadyForCooking === false && recipe.wasReadyForCooking) {
                if (!wantingWindowCustomer && Map2d.currentMap.getUnit(customer.unit.x, customer.unit.y)) {
                    wantingWindowCustomer = customer;
                }
            }

            recipe.getIngredients().forEach((ingredient) => {
                if (ingredient.unit) {
                    requiredUnits[Unit.GetKey(ingredient.unit)] = true;
                }
            });
        }
    });

    if (this.needUpdateMarks) {
        this.needUpdateMarks = false;

        Map2d.currentMap.listAvailableUnits((unit) => {
            unit.setCustomerMark(unit.stocked && requiredUnits[Unit.GetKey(unit)]);
        });
    }

    if (wantingWindowCustomer && cleverapps.config.name !== "hearts" && !cleverapps.gameModes.silentCustomers && !wantingWindowCustomer.withOrdersWindow) {
        wantingWindowCustomer.openWindow();
    }
};

Customers.prototype.findCustomerForHint = function (options) {
    const codes = options.type ? cleverapps.unitsLibrary.listCodesByType(options.type) : [options.code];
    const stage = options.stage;

    const producesType = function (customerCode) {
        const recipes = CustomerRecipes[cleverapps.meta.selectedLocationId()];
        return recipes[customerCode] && recipes[customerCode].some((template) => template.order.some((order) => {
            const orderCodes = order.type ? cleverapps.unitsLibrary.listCodesByType(order.type) : [order.code];
            return orderCodes.some((orderCode) => codes.some((code) => code === orderCode && (stage === undefined || order.stage === stage || (!Families[code].units[stage].unmergeable && stage > order.stage))));
        }));
    };

    const priority = Object.keys(Map2d.currentMap.fogs.config);
    const fakeUnits = Map2d.currentMap.fogs.listFakeUnits({ type: cleverapps.unitsLibrary.getExpeditionUnitType("customer") });

    fakeUnits.sort((a, b) => {
        const fogA = Map2d.currentMap.getFog(a.x, a.y);
        const fogB = Map2d.currentMap.getFog(b.x, b.y);
        return priority.indexOf(fogA && fogA.fogBlock && fogA.fogBlock.id) - priority.indexOf(fogB && fogB.fogBlock && fogB.fogBlock.id);
    });

    let fakeUnit;
    for (let i = 0; i < fakeUnits.length; i++) {
        fakeUnit = fakeUnits[i];
        for (let unitStage = 0; unitStage < Families[fakeUnit.code].units.length; unitStage++) {
            if (producesType(Unit.GetKey({ code: fakeUnit.code, stage: unitStage }))) {
                const real = this.customers.find((customer) => customer.unit.code === fakeUnit.code);
                return real && real.unit || fakeUnit;
            }
        }
    }
};

Customers.prototype.takeIngredients = function (ingredients, options) {
    options = options || {};
    const units = [];
    const resources = {};
    const customer = options.customer;
    let callback = options.callback || function () {};

    const unitsToRemove = {};

    ingredients.forEach((ingredient) => {
        if (ingredient.unit) {
            unitsToRemove[Unit.GetKey(ingredient.unit)] = ingredient.amount;
        }

        if (ingredient.ingredient) {
            resources[ingredient.ingredient] = (resources[ingredient.ingredient] || 0) + ingredient.amount;
        }
    });

    Map2d.currentMap.listAvailableUnits((unit) => {
        const key = Unit.GetKey(unit);
        if (unit.stocked && unitsToRemove[key]) {
            unitsToRemove[key] -= 1;
            units.push(unit);
        }
    });

    Merge.currentMerge.harvested.takeList(resources);

    callback = cleverapps.wait(units.length, callback);

    units.forEach((unit) => {
        unit.setCustomerMark(false);
        unit.takePrizes();
        unit.setPrizes();

        if (unit.points) {
            unit.claimPoints();
        }

        const pulsing = unit.findComponent(Pulsing);
        if (pulsing) {
            pulsing.setRemoved(true);
        }

        if (customer) {
            customer.collectUnit(unit, callback);
        } else {
            unit.remove();
            callback();
        }
    });
};

Customers.prototype.getStockAmount = function (ingredient) {
    if (!ingredient) {
        return 0;
    }

    if (ingredient.ingredient) {
        return Merge.currentMerge.harvested.get(ingredient.ingredient) || 0;
    }

    if (ingredient.unit) {
        return this.stock[Unit.GetKey(ingredient.unit)] || 0;
    }

    return 0;
};

Customers.prototype.getLackingAmount = function (ingredient) {
    return Math.max(0, ingredient.amount - this.getStockAmount(ingredient));
};

Customers.prototype.canSpawnIngredients = function (ingredients) {
    const unitsAmount = ingredients.reduce((total, ingredient) => {
        if (ingredient.unit) {
            return total + ingredient.amount;
        }
        return total;
    }, 0);

    const cellsToFree = unitsAmount - Map2d.currentMap.countEmptySlots();

    if (cellsToFree > 0) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: cellsToFree });
        return false;
    }
    return true;
};

Customers.prototype.spawnIngredients = function (ingredients, lacking) {
    const cell = Map2d.currentMap.getScreenCenterCell();

    for (let i = 0; i < ingredients.length; i++) {
        const ingredient = ingredients[i];
        const amount = lacking ? this.getLackingAmount(ingredient) : ingredient.amount;

        if (ingredient.unit) {
            for (let j = 0; j < amount; ++j) {
                const slot = Map2d.currentMap.findEmptySlot(cell.x, cell.y);

                const unit = new Unit(ingredient.unit);
                unit.setPosition(slot.x, slot.y);
                Map2d.currentMap.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
                Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                Map2d.currentMap.onUnitAvailable(unit);
            }
        }

        if (ingredient.ingredient) {
            Merge.currentMerge.harvested.add(ingredient.ingredient, amount);
        }
    }
};

Customers.prototype.canTakeIngredients = function (ingredients) {
    for (let i = 0; i < ingredients.length; i++) {
        const ingredient = ingredients[i];

        if (this.getStockAmount(ingredient) < ingredient.amount) {
            return false;
        }
    }
    return true;
};

Customers.prototype.listCustomers = function () {
    return this.customers;
};

Customers.prototype.listCustomersWithRecipe = function () {
    return this.customers.filter((customer) => customer.recipe);
};

Customers.prototype.findCanCook = function () {
    const customers = this.customers;
    for (let i = 0; i < customers.length; i++) {
        if (customers[i].canCook()) {
            return customers[i];
        }
    }

    return undefined;
};

Customers.prototype.findReady = function () {
    const customers = this.customers;
    for (let i = 0; i < customers.length; i++) {
        if (customers[i].getState() === Customer.STATE_READY) {
            return customers[i];
        }
    }

    return undefined;
};

Customers.prototype.requiredIngredientAmount = function (ingredient) {
    ingredient = ingredient.unit || ingredient.ingredient;
    let totalAmount = 0;
    this.customers.forEach((customer) => {
        if (customer.getState() !== Customer.STATE_RECIPE) {
            return;
        }
        customer.recipe.ingredients.forEach((item) => {
            if (
                (item.ingredient && item.ingredient === ingredient.code)
                || (item.unit && item.unit.code === ingredient.code && item.unit.stage === ingredient.stage)
            ) {
                totalAmount += item.amount;
            }
        });
    });
    return totalAmount;
};

Customers.prototype.getCustomRecipeIngredientCode = function () {
    const requiredCodes = {};
    this.customers.forEach((customer) => {
        if (customer.isProcessingCustomOrder()) {
            customer.recipe.ingredients.forEach((ingredient) => {
                if (this.getStockAmount(ingredient) < ingredient.amount) {
                    requiredCodes[ingredient.ingredient || ingredient.unit.code] = true;
                }
            });
        }
    });

    const neededCodes = Object.keys(requiredCodes);
    return neededCodes.length && cleverapps.Random.choose(neededCodes);
};

Customers.prototype.onUpdateOrder = function (customer) {
    this.trigger("onUpdateOrder", customer);
};

Customers.IGNORES_STOCK = ["unknown", "multiCellBody", "thirdelement", "barrel", "caravanship", "drgrowing", "seagrowing"];
