/**
 * Created by evgeny on 13.06.2024
 */

(function () {
    Unit.CreateHierarchyMenu = function (callback) {
        const menu = {};

        Object.keys(cleverapps.meta.selectedLocationId().families || {}).forEach((code) => {
            if (["unknown", "multiCellBody", "specialoffer"].indexOf(code) !== -1) {
                return;
            }

            const type = Families[code].type || "other";
            const units = {};

            for (let stage = 0; stage < Families[code].units.length; ++stage) {
                if (Families[code].units[stage].deleted) {
                    continue;
                }

                const unit = { code, stage };

                units[Unit.GetKey(unit)] = {
                    icon: UnitView.getUnitImage.bind(UnitView, unit),
                    action: callback.bind(null, unit)
                };
            }

            if (!menu[type]) {
                menu[type] = {};
            }

            menu[type][code] = units;
        });
        return menu;
    };

    Unit.CreateDebug = function (unit, cell) {
        const scene = cleverapps.scenes.getRunningScene();

        if (!cell) {
            cell = scene.innerMap.getCellByCoordinates(scene.map.getCurrentPosition());
            cell = Map2d.currentMap.findEmptySlot(cell.x, cell.y, unit, { radius: 0 });
        }

        if (cell && (Map2d.currentMap.isGround(cell.x, cell.y) || Map2d.currentMap.isWaterUnit(cell.x, cell.y))) {
            unit = new Unit(unit);
            unit.setPosition(cell.x, cell.y);
            Map2d.currentMap.add(Map2d.LAYER_UNITS, cell.x, cell.y, unit);
            Map2d.currentMap.onAddUnit(cell.x, cell.y, unit);
            Map2d.currentMap.onUnitAvailable(unit);

            Map2d.mapEvent(Map2d.SPAWN, { unit, debug: true });

            return true;
        }
    };

    UnitView.prototype.showTouchArea = function () {
        const used = {};
        const pool = [];
        let points = [];
        const siblings = [{ x: 1, y: 0 }, { x: 0, y: -1 }, { x: -1, y: 0 }, { x: 0, y: 1 }];

        const directions = [];
        let i = 0, j = 0, k = 1, l = 0, x = 0, y = 0;
        for (; k < 6;) {
            x += siblings[j % 4].x;
            y += siblings[j % 4].y;
            directions.push(cc.p(x, y));

            i += 1;

            if (i >= k) {
                j += 1;
                l += 1;
                i = 0;
            }

            if (l >= 2) {
                k += 1;
                l = 0;
            }
        }

        const acceptTouch = function (point) {
            point = this.convertToWorldSpace(point);
            return this.checkTouchInside(new cc.Touch(point.x, point.y));
        }.bind(this);

        y = Math.floor(this.height / 2);
        for (x = 0; x < this.width; ++x) {
            const point = cc.p(x, y);

            if (acceptTouch(point)) {
                pool.push(point);
                break;
            }
        }

        for (i = 0; i < pool.length; ++i) {
            const current = pool[i];

            for (j = 0; j < directions.length; ++j) {
                const next = cc.p(current.x + directions[j].x, current.y + directions[j].y);
                const key = (next.x + 1000) + (next.y + 1000) * 10000;

                if (used[key]) {
                    continue;
                }
                used[key] = true;

                if (!acceptTouch(next)) {
                    continue;
                }

                for (k = 0; k < siblings.length; ++k) {
                    if (!acceptTouch(cc.p(next.x + siblings[k].x, next.y + siblings[k].y))) {
                        points.push(next);
                        pool.push(next);
                        break;
                    }
                }

                if (k !== siblings.length) {
                    break;
                }
            }
        }

        points = points.filter((point, index) => !(index % 5));

        this.savedTouchArea = new cc.DrawNode();
        this.savedTouchArea.setAnchorPoint(0, 0);
        this.savedTouchArea.setContentSize2(this.width, this.height);
        this.savedTouchArea.drawDots(points, 1.2, cc.color(0, 0, 200, 255));
        this.savedTouchArea.setLocalZOrder(1000);
        this.addChild(this.savedTouchArea);
    };

    UnitView.prototype.debugBorder = cleverapps.extendFunc(UnitView.prototype.debugBorder, function (options) {
        this._super(options);
        if (engine === "cocos2d" && !this.savedTouchArea) {
            this.showTouchArea();
        }
    });

    UnitView.prototype.cleanupBorder = cleverapps.extendFunc(UnitView.prototype.cleanupBorder, function (options) {
        this._super(options);

        if (this.savedTouchArea) {
            this.savedTouchArea.removeFromParent();
            this.savedTouchArea = undefined;
        }
    });

    FogBlock.prototype.click = cleverapps.extendFunc(FogBlock.prototype.click, function (...args) {
        const time = Date.now();
        const timeout = 200;

        if (time - (this._toolLastClick || 0) < timeout) {
            clearTimeout(this.clickTimeout);
            delete this.clickTimeout;

            this._toolLastClick = 0;

            FogBlock.OpenDebug(this.id);
        } else {
            this._toolLastClick = time;
            const _super = this._super;
            this.clickTimeout = setTimeout(() => {
                _super.apply(this, args);

                delete this.clickTimeout;
            }, timeout);
        }
    });

    FogBlock.OpenDebug = function (targetId) {
        const scene = cleverapps.scenes.getRunningScene();

        const merge = Merge.currentMerge;
        const library = cleverapps.unitsLibrary;
        const fogs = merge.map.fogs.blocks;

        merge.tutorial.finishAll();

        const fogsToOpen = [];
        const findFogParent = function (fog) {
            fogsToOpen.unshift(fog);
            const options = fog.options || {};
            options.conditions = options.conditions || {};
            if (options.parentFogId !== undefined && fogs[options.parentFogId]) {
                findFogParent(fogs[options.parentFogId]);
            }
        };
        findFogParent(fogs[targetId]);

        merge.counter.setTimeout(() => {
            Merge.currentMerge.quests.quests = Merge.currentMerge.quests.quests.filter((quest) => !quest.getTutorial());
            Merge.currentMerge.quests.quests.forEach((quest) => {
                quest.setFresh(false);
            });
            Merge.currentMerge.quests.save();
        }, 1000);

        fogsToOpen.forEach((fog) => {
            const options = fog.options || {};
            options.conditions = options.conditions || {};

            merge.addReward("wands", options.price);
            fog.unlock();

            if (options.conditions.level) {
                while (cleverapps.gameLevel.getLevel() < options.conditions.level) {
                    cleverapps.exp.setExp(cleverapps.gameLevel.nextLevelThreshold(), true);
                    cleverapps.gameLevel.moveToNextLevel();
                }
                cleverapps.exp.onChange();
            }

            if (options.conditions.hero && !library.isHeroAvailable(options.conditions.hero)) {
                let unit = {
                    code: options.conditions.hero,
                    stage: Families[options.conditions.hero].units.length - 1
                };

                let cell = scene.innerMap.getCellByCoordinates(scene.map.getCurrentPosition());
                cell = merge.map.findEmptySlot(cell.x, cell.y, unit, { radius: 0 });

                if (cell) {
                    unit = new Unit(unit);
                    unit.setPosition(cell.x, cell.y);
                    merge.map.add(Map2d.LAYER_UNITS, cell.x, cell.y, unit);
                    merge.map.onAddUnit(cell.x, cell.y, unit);
                    merge.onUnitAvailable(unit);
                }
            }
        });
    };

    Unit.prototype.debugRemove = function (isDblClick) {
        if (cleverapps.focusManager.isFocused() || cleverapps.config.name === "wordsoup" || Map2d.currentMap.getUnit(this.x, this.y) !== this) {
            return;
        }
        const buildable = this.findComponent(Buildable);
        if (Map2d.currentMap.workers.findAssigned(this) || buildable && Map2d.currentMap.workers && Map2d.currentMap.workers.findInstantAssigned(buildable)) {
            return;
        }
        const mineable = this.findComponent(Mineable);
        if (mineable && cleverapps.config.subtype === "merge2" && isDblClick) {
            return false;
        }
        const collectible = this.findComponent(Collectible);
        const grounded = this.findComponent(Grounded);
        if (Map2d.currentMap.isFlat && collectible && !grounded) {
            return false;
        }
        if (this.view && this.view.getParent() === Map2d.currentMap.getMapView().animations) {
            return;
        }

        InfoView.CloseInfo();
        this.onDestruction();
        this.remove(true);
        this.onClickEvent = function () {};
        Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
    };

    UnitView.prototype.handleClick = cleverapps.extendFunc(UnitView.prototype.handleClick, function (...args) {
        if (cleverapps.keyboardController.isPressed(cc.KEY.c) && cleverapps.config.name !== "wordsoup") {
            Merge.currentMerge.copyUnit(this.unit);
            return;
        }

        if (cleverapps.keyboardController.isPressed(cc.KEY.x)) {
            const url = cleverapps.RestClient.standsWeb(`/?unitsDemo=${cleverapps.meta.selectedLocationId()}&code=${this.unit.code}`);
            connector.platform.openUrl(url);
            return;
        }

        const locked = this.unit.findComponent(LockedComponent);
        if (cleverapps.keyboardController.isPressed(cc.KEY.l) && locked) {
            locked.setLockActive(!locked.isLockActive());
            return;
        }

        if (this._toolLastClick + PointerHandler.DOUBLE_CLICK_WAIT > Date.now()) {
            const unitRemoved = this.unit.debugRemove(true);
            if (unitRemoved !== false) {
                if (Map2d.currentMap.isFlat && this.unit.findComponent(Grounded)) {
                    const pos = { x: this.unit.x, y: this.unit.y };
                    const unit = new Unit({ code: this.unit.code, stage: this.unit.stage });
                    unit.setPosition(pos.x, pos.y);
                    Map2d.currentMap.add(Map2d.LAYER_UNITS, pos.x, pos.y, unit);
                    Map2d.currentMap.onAddUnit(pos.x, pos.y, unit);
                }

                return true;
            }
        }

        this._toolLastClick = Date.now();
        return this._super.apply(this, args);
    });

    Map2dInnerView.prototype.addControls = cleverapps.extendFunc(Map2dInnerView.prototype.addControls, function (...args) {
        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseDown: function (event) {
                if (!this.isDisplayed()) {
                    return false;
                }

                if (event.getButton() === cc.EventMouse.BUTTON_RIGHT) {
                    const unit = this.findTouchUnitOrDecorator(event);
                    if (unit) {
                        if (cleverapps.keyboardController.isPressed(cc.KEY.shift)) {
                            Map2d.currentMap.listAvailableUnits(unit).forEach((unit) => {
                                unit.debugRemove();
                            });
                        } else {
                            unit.debugRemove();
                        }
                    }
                }
            }.bind(this)
        }, this);

        this._super.apply(this, args);
    });
}());