/**
 * Created by mac on 2/25/20
 */

const Fogs = function (map2d) {
    cleverapps.EventEmitter.call(this);
    this.map2d = map2d;

    this.wands = 0;

    this.loadConfig();

    this.wantsCalcFogStates = undefined;

    this.blocks = {};
    this.fakeUnits = Fogs.ReadDefaults(map2d.field);
};

Fogs.prototype = Object.create(cleverapps.EventEmitter.prototype);
Fogs.prototype.constructor = Fogs;

Fogs.prototype.load = function (stored) {
    if (["mergecraft", "wondermerge", "fairy", "hustlemerge"].indexOf(cleverapps.config.name) === -1) {
        stored = Fogs.fromBit(stored, this.config);
    }

    const openedFogs = Object.keys(this.config).filter(function (type) {
        return this.config[type].opened || stored.indexOf(type) !== -1;
    }, this);

    const unopenedFogs = Object.keys(this.config).filter((type) => openedFogs.indexOf(type) === -1, this);

    for (let i = 0; i < openedFogs.length;) {
        const fog = this.config[openedFogs[i]];

        if (fog.available === false || fog.debugMode === true && !cleverapps.config.debugMode) {
            unopenedFogs.push(openedFogs[i]);
            openedFogs.splice(i, 1);
        } else {
            i += 1;
        }
    }

    const regions = this.map2d.regions;

    Object.keys(this.config).forEach(function (id) {
        this.config[id].emptyRegion = !regions[id];
    }, this);

    unopenedFogs.forEach((id) => {
        const block = new FogBlock(id, this);

        const region = regions[id];
        if (!region) {
            return;
        }

        this.addBlockTiles(block, region);

        if (cleverapps.config.debugMode && block.isMoney() && !block.options.bubbles) {
            block.tiles.forEach((tile) => {
                let unit = this.fakeUnits[tile.x] && this.fakeUnits[tile.x][tile.y];
                if (unit === undefined) {
                    console.log(`No unit on ${block.id} on x:${tile.x} y:${tile.y}`);
                    return;
                }
                unit = unit.head || unit;
                if (Families[unit.code].units[unit.stage].price === undefined) {
                    alert(`No price for ${unit.code} ${unit.stage}`);
                }
            });
        }
        this.blocks[id] = block;
    });

    Object.values(this.blocks).forEach((block) => {
        block.prepareOpenDelay();
    });
};

Fogs.prototype.getInfo = function () {
    let info = cleverapps.substract(Object.keys(this.config), Object.keys(this.blocks || {})).filter(function (id) {
        return !this.config[id].emptyRegion;
    }, this);

    if (["mergecraft", "wondermerge", "fairy", "hustlemerge"].indexOf(cleverapps.config.name) === -1) {
        info = Fogs.toBit(info, this.config);
    }

    return info;
};

Fogs.prototype.setWands = function (amount) {
    this.wands = amount;
    for (const id in this.blocks) {
        this.blocks[id].onChangeWandsAmount(amount);
    }
};

Fogs.prototype.takeWands = function (wands) {
    this.trigger("takeWands", wands);
};

Fogs.prototype.addBlockTiles = function (block, region) {
    region.positions.sort((a, b) => a.y - b.y || b.x - a.x).forEach((p) => {
        const head = region.head && cc.pointEqualToPoint(p, region.head);
        block.add(p.x, p.y, { head });
    });

    this.addBlockBalloon(block, region);
};

Fogs.prototype.getTilesWithDecorators = function (block, fogCellsOnly) {
    const map = this.map2d;
    const used = {};
    const decorTiles = [];

    const buyFogCells = {};
    if (map.regions.buyFogCell) {
        map.regions.buyFogCell.positions.forEach((cell) => {
            buyFogCells[`${cell.x}_${cell.y}`] = 1;
        });
    }
    const fogCellsKeys = block.tiles.map((tile) => map.getPositionKey(tile.x, tile.y));

    const visit = function (tile, firstPass) {
        ISO_NEIGHBORS.concat([Iso.DOWN_BELOW, Iso.HORIZONTAL_LEFT, Iso.HORIZONTAL_RIGHT, Iso.UP_ABOVE]).forEach((dir) => {
            const decorTile = {
                x: tile.x + dir.x,
                y: tile.y + dir.y
            };

            const key = map.getPositionKey(decorTile.x, decorTile.y);
            if (!used[key]) {
                used[key] = true;

                const unit = map.getUnit(decorTile.x, decorTile.y);

                const isBuyFogCell = buyFogCells[`${decorTile.x}_${decorTile.y}`];

                if (firstPass || !unit && !map.isImpassableGround(decorTile.x, decorTile.y) || isBuyFogCell) {
                    if (!fogCellsOnly || (fogCellsOnly && fogCellsKeys.indexOf(key) !== -1)) {
                        decorTiles.push(decorTile);
                    }
                }

                if (isBuyFogCell) {
                    visit(decorTile);
                }
            }
        });
    };

    block.tiles.forEach(visit, true);

    decorTiles.sort((a, b) => b.x - a.x || a.y - b.y);

    return decorTiles;
};

Fogs.prototype.addBlockBalloon = function (block, region) {
    if (region.positions.length > 0 && block.isMoney()) {
        if (!region.balloons) {
            region.balloons = FogBalloon.ChoosePosition(region.positions, region.head);
        }
        if (region.balloons) {
            block.addBalloon(region.balloons.x, region.balloons.y);
        }
    }
};

Fogs.ReadMapUnit = function (item) {
    item = cleverapps.clone(item, true);

    const family = Families[item.code];
    if (!family && !cleverapps.config.wysiwygMode) {
        cleverapps.throwAsync(`unknown unit while reading map data ${item.code}`);
        return;
    }

    const data = Families[item.code].units[item.stage];

    item.type = Families[item.code].type;
    item.getType = function () {
        return item.type;
    };

    if (item.grounded !== false && (data.source || data.initiallyGrounded)) {
        item.grounded = true;
    }

    if (data.multicell) {
        item.multicell = data.multicell;
    }

    if (data.pointOfInterest) {
        item.pointOfInterest = data.pointOfInterest;
    }

    if (data.important) {
        item.important = data.important;
    }

    item.fresh = true;

    return item;
};

Fogs.ReadDefaults = function (defaults) {
    const units = {};
    defaults.forEach((item) => {
        item = Fogs.ReadMapUnit(item);
        if (!item) {
            return;
        }

        const x = item.x;
        const y = item.y;

        if (units[x] === undefined) {
            units[x] = {};
        }

        units[x][y] = item;

        const shape = Unit.GetShape(item);
        if (shape.length > 1) {
            shape.forEach((delta) => {
                if (delta.x || delta.y) {
                    const nx = x + delta.x;
                    const ny = y + delta.y;

                    if (units[nx] === undefined) {
                        units[nx] = {};
                    }

                    units[nx][ny] = {
                        head: item,
                        x: nx,
                        y: ny
                    };
                }
            });
        }
    });
    return units;
};

Fogs.ReadConfig = function (locationId) {
    return FogsConfig[locationId] || FogsConfig.main;
};

Fogs.fromBit = function (bit, config) {
    const ids = cleverapps.createSet(cleverapps.fromBit(bit));

    return Object.keys(config).filter((code) => ids[config[code].id], this);
};

Fogs.toBit = function (fogs, config) {
    const ids = fogs.map((fog) => config[fog].id, this);
    return cleverapps.toBit(ids, Fogs.SYNCED_BITS);
};

Fogs.SYNCED_NUMBERS = 2;
Fogs.SYNCED_BITS = cleverapps.SYNCED_BITS * Fogs.SYNCED_NUMBERS;

Fogs.prototype.initStage = function () {
    this.initStageRunned = true;
};

Fogs.prototype.open = function (id, callback) {
    delete this.blocks[id];
    callback = callback || function () {};
    this.map2d.blockedGrounds.updateBlockedGrounds(callback);
    this.map2d.workers.placeNotPositionedWorkers(id);

    this.wantsCalcFogStates = true;

    this.map2d.saveFogs();
    cleverapps.offerManager.refreshByFog(id);

    this.trigger("open");
};

Fogs.prototype.loadConfig = function () {
    this.config = Fogs.ReadConfig(cleverapps.meta.selectedLocationId());
};

Fogs.prototype.calcFogStates = function () {
    for (const id in this.blocks) {
        this.blocks[id].calcState();
    }
};

Fogs.prototype.calcFogStatesStage = function () {
    if (this.wantsCalcFogStates && !cleverapps.focusManager.isFocused()) {
        this.wantsCalcFogStates = undefined;
        this.calcFogStates();
    }
};

Fogs.prototype.isOpened = function (fogId) {
    return !this.blocks[fogId];
};

Fogs.prototype.getBlockById = function (fogId) {
    return this.blocks[fogId];
};

Fogs.prototype.findAvailableFogBlock = function () {
    let result;

    for (const id in this.blocks) {
        const block = this.blocks[id];

        if (block.state !== FogBlock.UNAVAILABLE) {
            if (!result || result.state < block.state) {
                result = block;
            }
        }
    }
    return result;
};

Fogs.prototype.showBalloons = function (silent) {
    for (const i in this.blocks) {
        this.blocks[i].showBalloon(silent);
    }
};

Fogs.prototype.hideBalloons = function () {
    for (const i in this.blocks) {
        this.blocks[i].hideBalloon();
    }
};

Fogs.prototype.findBlockWithProgress = function () {
    return Object.values(this.blocks).filter((block) => block.state === FogBlock.CANTOPEN && !block.canOpen() && !block.isMoney() && !block.isSpecial()).sort((a, b) => a.getOpenPrice() - b.getOpenPrice())[0];
};

Fogs.prototype.findFogReadyToOpen = function () {
    for (const i in this.blocks) {
        const fogBlock = this.blocks[i];
        if (fogBlock.options.available !== false && fogBlock.state === FogBlock.CANOPEN
            && !fogBlock.isMoney() && fogBlock.canOpen() && fogBlock.isReady()) {
            return this.blocks[i];
        }
    }
};

Fogs.prototype.findTile = function (x, y) {
    for (const i in this.blocks) {
        const fogBlock = this.blocks[i];
        const tile = fogBlock.findTile(x, y);
        if (tile) {
            return tile;
        }
    }
};

Fogs.prototype.findFakeUnit = function (targetUnit) {
    for (const row in this.fakeUnits) {
        for (const col in this.fakeUnits[row]) {
            if (Unit.IsApplicable(targetUnit, this.fakeUnits[row][col])) {
                return this.fakeUnits[row][col];
            }
        }
    }
};

Fogs.prototype.listFakeUnits = function (filter) {
    if (filter && typeof filter !== "function") {
        filter = Unit.IsApplicable.bind(Unit, filter);
    }

    const units = [];

    for (const row in this.fakeUnits) {
        for (const col in this.fakeUnits[row]) {
            if (!this.fakeUnits[row][col].head && filter(this.fakeUnits[row][col])) {
                units.push(this.fakeUnits[row][col]);
            }
        }
    }

    return units;
};

Fogs.prototype.getFakeUnit = function (x, y) {
    return this.fakeUnits[x] && this.fakeUnits[x][y];
};

Fogs.prototype.isPresentOnMap = function (unit) {
    if (!this.presentedUnits) {
        const presentedUnits = {};

        for (const row in this.fakeUnits) {
            for (const col in this.fakeUnits[row]) {
                if (!this.fakeUnits[row][col].head) {
                    presentedUnits[Unit.GetKey(this.fakeUnits[row][col])] = true;
                }
            }
        }

        this.presentedUnits = presentedUnits;
    }

    return this.presentedUnits[Unit.GetKey(unit)];
};
